import axios from "axios";

const BASE_DOMAIN = 'https://2koleso.ru';

export default ({
    state: {
        loadedFirstTime: false,
        page: 0,
        products: [],
        filteredProducts: [],
        available: [],
        PRODUCTS_REQUEST_LOADING: true,
        PRODUCTS_REQUEST_ERRORS: false,
        PRODUCTS_REQUEST_STATUS: 'Первичная обработка данных',
        filter: {
            width: '',
            height: '',
            diametr: '',
            sparka: null,
            sparka_width: '',
            sparka_height: '',
            sparka_diametr: '',
            brand: '',
            model: '',
            season: '',
            ships: null,
            runflat: null,
            light: null,
            mt: null,
            at: null,
            articul: '',
            total: '',
            sort: '',
        },
        filter_props: {
            width: [],
            height: [],
            diametr: [],
            sparka: [],
            sparka_width: [],
            spartka_height: [],
            sparka_width2: [],
            brand: [],
            model: [],
            season: [],
            ships: [],
        },
        choosedProduct: '',
        countProduct: ''
    },
    mutations: {
        LOADED_FIRST_TIME: (state) => state.loadedFirstTime = true,
        TOGGLE_PRODUCTS_REQUEST_LOADING: (state, payload) => state.PRODUCTS_REQUEST_LOADING = payload,
        TOGGLE_PRODUCTS_REQUEST_ERRORS: (state, payload) => state.PRODUCTS_REQUEST_ERRORS = payload,
        SET_PRODUCTS: (state, payload) => state.products = payload,
        SET_FILTER_PROPS: (state, payload) => state.filter_props = {...state.filter_props, ...payload},
        SET_AVAILABLE: (state, payload) => state.available = payload,
        SET_PRODUCTS_REQUEST_STATUS: (state, payload) => state.PRODUCTS_REQUEST_STATUS = payload,
        SET_PAGE(state, page) {
            state.page = page;

        },
        chooseProduct: (state,payload) =>{
            state.choosedProducs = payload
        },
        filterReset: (state) =>{
            state.filter = {
                width: '',
                height: '',
                diametr: '',
                sparka: null,
                sparka_width: '',
                spartka_height: '',
                sparka_width2: '',
                brand: '',
                model: '',
                season: '',
                ships: '',
                runflat: null,
                light: null,
                mt: null,
                at: null,
                articul: '',
                total: '',
                sort: '',
                name: '',
                size: ''
            }
        }
    },
    actions: {
        LOAD_FILTER_PROPS_REQUEST: ({commit}) => {
            axios.get(BASE_DOMAIN + '/filter-values.php').then(response => {
                commit('SET_FILTER_PROPS', response.data.filter_values);
            })
        },
        LOAD_PRODUCTS_REQUEST: ({state, commit}) => {
            commit('TOGGLE_PRODUCTS_REQUEST_LOADING', true);
            let data = {
                page: state.page,
                filter: {}
            }
            Object.keys(state.filter).forEach(key => {
                if (state.filter[key] !== null && state.filter[key] !== '') {
                    data.filter[key] = state.filter[key];
                }
            })
            axios.post(BASE_DOMAIN + '/products.php', data).then((response) => {
                let products = response.data;
                commit('SET_PRODUCTS', products);
                commit('TOGGLE_PRODUCTS_REQUEST_LOADING', false);
                commit('LOADED_FIRST_TIME');
            })
        },
        //FILTER_PRODUCTS: ({commit}) => {
        //     commit('TOGGLE_PRODUCTS_REQUEST_LOADING', true);
        //    commit('filterProducts');
        //    setTimeout(() => {
        //         commit('TOGGLE_PRODUCTS_REQUEST_LOADING', false);
        //     }, 1000)
        // },
        filterReset: ({commit}) =>{
            commit('filterReset')
        }
    },
    getters: {
        getFilter(state) {
            return state.filter
        },
        getFilterProps(state) {
            return state.filter_props
        },
        getChoosedProduct(state){
            return state.choosedProduct
        },
        getState(state){
            return state
        }
    }
})

