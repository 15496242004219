<template>
  <tbody id="page">
  <Item @open="$emit('open')" v-for="item in data.list" :item="item" :key="item.code"/>
  <slot></slot>
  <ul class="pagination" v-if="pagesCounter.length > 0">
    <li class="prev page-item" @click="prev">
      <img src="@/assets/prev.png" alt="">
    </li>
    <li class="page-item" v-for="(p,i) in pagesCounter" :key="i">
      <button @click="setPage(p)" :class="{active: p === page}">{{ p + 1 }}</button>
    </li>
    <li class="next page-item" @click="next">
      <img src="@/assets/next.png" alt="">
    </li>
  </ul>
  <tr class="nothing-found" v-else>
    <td colspan="9">
      Ничего не найдено
    </td>
  </tr>
  </tbody>
</template>

<script>
import Item from "@/components/Item";

export default {
  name: "Pagination",
  props: {
    data: {
      required: true,
      type: Object
    },
  },
  components: {
    Item
  },
  data: function () {
    return {
      page: this.$store.state.products.page,
    }
  },
  watch: {
    page(newPage) {
      this.$store.commit('SET_PAGE', newPage);
      this.$store.dispatch('LOAD_PRODUCTS_REQUEST');
    }
  },
  computed: {
    pagesCounter() {
      let res = [];
      if (this.page === 1 || this.page === 0) {
        res = [0, 1, 2, 3, 4];
      } else if (this.page === 2) {
        res = [0, 1, 2, 3, 4];
      } else if (this.page === this.data.pages_count) {
        res = [this.page - 3, this.page - 2, this.page - 1];
      } else {
        res = [this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2]
      }
      res = res.filter(page => page < this.data.pages_count && page >= 0);
      return res;
    },
  },
  methods: {
    setPage(page) {
      this.page = page;
    },
    prev() {
      if(this.page !== 0)
        this.page = this.page - 1
    },
    next() {
      this.page = this.page + 1
    }
  },
}
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  @media (max-width: 1024px) {
    justify-content: space-between;
    padding: 0 15px;
  }
  list-style: none;
  margin: 15px auto;

  .page-item {
    margin-right: 20px;
    display: block;
    cursor: pointer;

    button {
      border: 0;
      font-size: 22px;
      margin-right: 10px;
      color: #aab3bc;
      cursor: pointer;
      background-color: transparent;

      &.active {
        color: #0287ff;
      }

      &:focus {
        outline: none;
      }
      &:hover{
        color: #cde7fe!important;
      }
    }
  }
}
.nothing-found {
  font-size: 32px;
  text-align: center;
  td {
    padding: 32px 0;
  }
}

</style>
